import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('../views/Collection.vue')
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('../views/Community.vue')
  },
  {
    path: '/detail/:uuid?',
    name: 'Detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/community_detail/:uuid?',
    name: 'CommunityDetail',
    component: () => import('../views/CommunityDetail.vue')
  },
  {
    path: '/stores',
    name: 'Store',
    component: () => import('../views/Stores.vue')
  },
  {
    path: '/activity/:id?',
    name: 'Activity',
    component: () => import('../views/Activity.vue')
  },
  // {
  //   path: '/vacancy',
  //   name: 'Vacancy',
  //   component: () => import('../views/Vacancy.vue')
  // },
  {
    path: '/recruiting',
    name: 'Recruiting',
    component: () => import('../views/Recruiting.vue')
  },
  {
    path: '/real_price',
    name: 'RealPrice',
    component: () => import('../views/RealPrice.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
