import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import 'bootstrap'
import 'mmenu-light/dist/mmenu-light.css'
import 'mmenu-light/dist/mmenu-light.js'
import '@/assets/plugin/slick.css'
import '@/assets/plugin/slick.min.js'
import '@/assets/sass/main.sass'
import axios from 'axios'
import VueAxios from 'vue-axios'

const $ = jQuery
window.$ = $

const app = createApp(App)

app.use(VueAxios, axios)
app.use(router).mount('#app')
