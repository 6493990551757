<template>
    <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8485 0.751862C10.3799 0.283233 9.62009 0.283233 9.15147 0.751862L0.751466 9.15186C0.282837 9.62049 0.282837 10.3803 0.751466 10.8489C1.22009 11.3175 1.97989 11.3175 2.44852 10.8489L2.79999 10.4974V18.4004C2.79999 19.0631 3.33725 19.6004 3.99999 19.6004H6.39999C7.06274 19.6004 7.59999 19.0631 7.59999 18.4004V16.0004C7.59999 15.3376 8.13725 14.8004 8.79999 14.8004H11.2C11.8627 14.8004 12.4 15.3376 12.4 16.0004V18.4004C12.4 19.0631 12.9373 19.6004 13.6 19.6004H16C16.6627 19.6004 17.2 19.0631 17.2 18.4004V10.4974L17.5515 10.8489C18.0201 11.3175 18.7799 11.3175 19.2485 10.8489C19.7172 10.3803 19.7172 9.62049 19.2485 9.15186L10.8485 0.751862Z" fill="#DD4C27"/>
    </svg>
</template>

<script>
export default {
  name: 'IconHome'
}
</script>

