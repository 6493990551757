<template>
    <footer class="page-footer">
        <div class="footer-above">
            <div class="container">
                <div class="above-wrapper">
                    <div class="footer-brand">
                        <div class="footer-logo">
                            <img :src="website.footerLogo" class="w-100" alt="LOGO" v-if="website.footerLogo != undefined && website.footerLogo != ''">
                        </div>
                        <div class="footer-social">
                            <ul>

                                <li v-if="website.facebook != ''">
                                    <a :href="website.facebook" target="_blank">
                                        <i class="icon">
                                            <img src="@/assets/images/icon-facebook.svg" alt="facebook" class="w-100">
                                        </i>
                                    </a>
                                </li>
                                <li v-if="website.instagram != ''">
                                    <a :href="website.instagram" target="_blank">
                                        <i class="icon">
                                            <img src="@/assets/images/icon-instagram.svg" alt="instagram" class="w-100">
                                        </i>
                                    </a>
                                </li>
                                <li v-if="website.youtube != ''">
                                    <a :href="website.youtube" target="_blank">
                                        <i class="icon">
                                            <img src="@/assets/images/icon-youtube.svg" alt="youtube" class="w-100">
                                        </i>
                                    </a>
                                </li>
                                <li v-if="website.icon != '' && website.iconHref != ''">
                                    <a :href="website.iconHref" target="_blank">
                                        <i class="icon">
                                            <img :src="website.icon" class="w-100">
                                        </i>
                                    </a>
                                </li>
                                <li v-if="website.line != ''">
                                    <a :href="website.line" target="_blank">
                                        <i class="icon">
                                            <img src="@/assets/images/icon-line.svg" alt="line" class="w-100">
                                        </i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer-meta">
                        <ul class="d-block d-md-flex">
                            <li class="meat-item">
                                <h3>聯絡我們</h3>
                                <ul class="info-list">
                                    <li class="list" v-if="website.email != ''">
                                        <i class="icon me-2"><img src="@/assets/images/icon-mail.svg" alt="line" class="w-100"></i>
                                        {{ website.email }}
                                    </li>
                                    <li class="list">
                                        <i class="icon me-2"><img src="@/assets/images/icon-phone2.svg" alt="line" class="w-100"></i>{{ website.telService }}
                                    </li>
                                    <li class="list">
                                        <i class="icon me-2"><img src="@/assets/images/icon-location-marker.svg" alt="line" class="w-100"></i>
                                        <a :href="'https://www.google.com.tw/maps?q=' + website.address" target="_blank">{{ website.address }}</a>
                                    </li>
                                    <li class="list">
                                        <i class="icon me-2"><img src="@/assets/images/icon-clock.svg" alt="line" class="w-100"></i>{{ website.businessHours }}
                                    </li>
                                </ul>
                            </li>
                            <li class="service-phone" v-if="website.telFranchise">
                                <div class="service-box">
                                    <h4>
                                        <IconHome class="me-2"/>
                                        客服專線
                                    </h4>
                                    <h5>{{ website.telFranchise }}</h5>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright text-center">
            Copyright © {{ website.copyright }}
        </div>
    </footer>
</template>

<script>
import IconHome from '@/components/icons/IconHome.vue'

export default {
    name: 'PgFooter',
    props: {
        website: Object
    },
    components: {
        IconHome
    },
    mounted() {
    }
}
</script>

