<template>
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.44446C0 4.29395 0.0432792 4.16371 0.129846 4.05382L3.1924 0.164981C3.27896 0.0549936 3.38152 0 3.49998 0C3.61848 0 3.72104 0.0549936 3.8076 0.164981L6.87011 4.05382C6.95672 4.16371 7 4.29396 7 4.44446C7 4.59488 6.95672 4.72503 6.87011 4.83502C6.78354 4.94501 6.68099 5 6.56248 5H0.437473C0.319014 5 0.216459 4.94501 0.129846 4.83502C0.0432792 4.72503 0 4.59488 0 4.44446Z" fill="white"/>
    </svg>
</template>

<script>
export default {
  name: 'IconCaretDown'
}
</script>

