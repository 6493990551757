<template>
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 2.80039C1.8 1.47491 2.87452 0.400391 4.2 0.400391H13.8C15.1255 0.400391 16.2 1.47491 16.2 2.80039V17.2004C16.8627 17.2004 17.4 17.7376 17.4 18.4004C17.4 19.0631 16.8627 19.6004 16.2 19.6004H12.4C11.8477 19.6004 11.4 19.1527 11.4 18.6004V16.0004C11.4 15.3376 10.8627 14.8004 10.2 14.8004H7.8C7.13726 14.8004 6.6 15.3376 6.6 16.0004V18.6004C6.6 19.1527 6.15228 19.6004 5.6 19.6004H1.8C1.13726 19.6004 0.599998 19.0631 0.599998 18.4004C0.599998 17.7376 1.13726 17.2004 1.8 17.2004V2.80039ZM5.4 4.00039H7.8V6.40039H5.4V4.00039ZM7.8 8.80039H5.4V11.2004H7.8V8.80039ZM10.2 4.00039H12.6V6.40039H10.2V4.00039ZM12.6 8.80039H10.2V11.2004H12.6V8.80039Z" fill="#DD4C27"/>
        </svg>
</template>

<script>
export default {
    name: 'IconBuild'
}
</script>

