<template>
    <div id="LaunchPage">
        <div class="header-container">
            <!-- Header Classname : default -> 斜切背景圖 / single-color -> 單色 / bgimg -> 設定背景圖 -->
            <header :class="'page-header ' + headerClass">
                <div class="container">
                    <nav class="navbar">
                        <button class="menutoggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#mmenu" aria-controls="mmenu">
                            <div class="menu-icon">
                                <span class="menu-icon_bar menu-icon_bar-first"></span>
                                <span class="menu-icon_bar"></span>
                                <span class="menu-icon_bar menu-icon_bar-last"></span>
                            </div>
                        </button>
                        <div class="navbar-brand">
                            <div data-href="index.html" :title="website.siteName" class="pagetrans" v-if="website.logo != undefined && website.logo != ''">
                                <div class="branding-image">
                                    <router-link to="/"><img :src="website.logo" alt="LOGO" class="header-logo"></router-link>
                                </div>
                                <h1 class="visually-hidden">{{ website.siteName }}</h1>
                            </div>
                        </div>
                        <div class="navbar-warpper d-none d-lg-flex ml-auto">
                            <ul class="navbar-nav">
                                <template v-for="item in headerMenu.header">
                                    <li class="nav-item" v-if="item.to != ''">
                                        <router-link :to="item.to" class="nav-link" v-if="item.target == ''"><span class="text">{{ item.name }}</span></router-link>
                                        <a :href="item.to" target="_blank" class="nav-link" v-else><span class="text">{{ item.name }}</span></a>
                                    </li>

                                    <li class="nav-item has-submenu" v-else>
                                        <a herf="javascript:;" class="nav-link"><span class="text">{{ item.name }}</span><i class="arr">
                                            <IconCaretDown/>
                                        </i></a>
                                        <ul class="hover_dropdown">
                                            <li class="menu-item" v-for="children in item.children">
                                                <router-link :to="children.to" class="menu-link second_link" v-if="children.target == ''">{{ children.name }}</router-link>
                                                <a :href="children.to" target="_blank" class="menu-link second_link" v-else>{{ children.name }}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="bgimg-container" v-if="headerClass == 'bgimg'">
                    <img :src="website.headerImageMobile" class="mb d-block d-lg-none">
                    <img :src="website.headerImagePc" class="pc d-none d-lg-block">
                </div>
            </header>
            <div class="offset_block"></div>
        </div>

        <router-view/>
        <PgFooter :website="website"/>
        <div class="offcanvas offcanvas-end mmenu" data-bs-scroll="true" tabindex="-1" id="mmenu" aria-labelledby="mmenuLabel">
            <div class="offcanvas-header">
                <button type="button" class="close-menu" data-bs-dismiss="offcanvas" aria-label="Close">
                    <IconClose/>
                </button>
            </div>
            <div class="offcanvas-body p-0">
                <ul class="mmenu-group">
                    <template v-for="(item, index) in headerMenu.header">
                        <li :class="'item' + (index == 0 ? ' active' : '')" data-bs-dismiss="offcanvas" v-if="item.to != ''">
                            <router-link :to="item.to" class="menu-link" v-if="item.target == ''"><span class="text">{{ item.name }}</span></router-link>
                            <a :href="item.to" target="_blank" class="menu-link" v-else><span class="text">{{ item.name }}</span></a>
                        </li>
                        <li class="item" v-else>
                            <button class="w-100 menu-link menu-collapse" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + item.id" aria-expanded="false">
                                <span class="text me-2">{{ item.name }}</span>
                                <i class="icon">
                                    <IconCaretDown/>
                                </i>
                            </button>
                            <div class="collapse" :id="'collapse-' + item.id">
                                <ul>
                                    <li class="menu-item second-menu" data-bs-dismiss="offcanvas" v-for="children in item.children">
                                        <router-link :to="children.to" class="menu-link" v-if="children.target == ''">{{ children.name }}</router-link>
                                        <a :href="children.to" target="_blank" class="menu-link" v-else><span class="text">{{ children.name }}</span></a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import PgFooter from '@/components/icons/PgFooter.vue'
import IconCaretDown from '@/components/icons/IconCaretDown.vue'
import IconClose from '@/components/icons/IconClose.vue'
// import axios from "axios";

export default {
    name: 'App',
    data() {
        return {
            website: {},
            headerClass: 'single-color',
            headerMenu: {
                header: {},
                dropdown: {},
            },
        }
    },
    components: {
        PgFooter,
        IconCaretDown,
        IconClose
    },
    methods: {
        init: function (response) {
            this.website = response.data.website;
            if (this.website.headerImagePc != '' || this.website.headerImageMobile != '') {
                this.headerClass = 'bgimg';
            }
            this.headerMenu = response.data.headerMenu;
            // document.querySelector("link[rel~='icon']").href = this.website.favicon;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        this.axios.get('/api/init')
            .then(response => (
                    this.init(response)
                )
            )
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    },
    mounted() {
        let headerMenu = this.headerMenu.header;
        $("body").on("click", ".nav-item", function () {
            $('.nav-item').removeClass('active');
            $(this).addClass('active');
        });

        $("body").on("click", ".pagetrans", function (event) {
            $('.nav-item').removeClass('active');
            $('.nav-item').eq(0).addClass('active');
        });

        $("body").on("click", ".hover_dropdown .second_link", function (event) {
            $('.hover_dropdown a').removeClass('active');
            $(this).addClass('active');
        });

        $("body").on("click", ".second-menu .menu-link", function (event) {
            $('.hover_dropdown a').removeClass('active');
            $(this).addClass('active');
            for (let x = 0; x < headerMenu.length; x++) {
                if (headerMenu[x].to == '') {
                    $('#collapse-' + headerMenu[x].id).collapse("hide");
                }
            }
        });

        $("body").on("click", ".mmenu-group .menu-link", function (event) {
            $('.mmenu-group .menu-link').removeClass('active');
            $(this).addClass('active');
        });

        window.setTimeout(function () {
            $('.router-link-active.nav-link').parent().addClass('active');
        }, 500);

        this.$router.afterEach((to, from) => {
            this.scrollToTop();
        });
    }
}
</script>
