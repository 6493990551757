<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.85873 1.71199C8.21795 0.606419 9.78204 0.60642 10.1413 1.71199L11.4248 5.66215C11.5854 6.15658 12.0461 6.49133 12.566 6.49133H16.7195C17.8819 6.49133 18.3653 7.97887 17.4248 8.66215L14.0646 11.1035C13.644 11.4091 13.468 11.9507 13.6287 12.4451L14.9122 16.3953C15.2714 17.5009 14.006 18.4202 13.0655 17.7369L9.70534 15.2956C9.28476 14.99 8.71524 14.99 8.29466 15.2956L4.93445 17.7369C3.994 18.4202 2.72862 17.5009 3.08784 16.3953L4.37133 12.4451C4.53198 11.9507 4.35599 11.4091 3.9354 11.1035L0.575197 8.66215C-0.365259 7.97887 0.118072 6.49133 1.28054 6.49133H5.43398C5.95385 6.49133 6.4146 6.15658 6.57525 5.66215L7.85873 1.71199Z" fill="#DD4C27"/>
    </svg>
</template>

<script>
export default {
  name: 'IconStar'
}
</script>

