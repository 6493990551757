<template>
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.31098 6.99955L0.321294 11.5826C-0.0798264 11.9583 -0.109386 12.5977 0.255264 13.0109C0.619914 13.4241 1.24069 13.4545 1.64181 13.0789L7.43042 7.7477C7.8588 7.34654 7.8588 6.65257 7.43042 6.2514L1.64181 0.920181C1.24069 0.544551 0.619924 0.575 0.255264 0.988191C-0.109386 1.40138 -0.0798264 2.04084 0.321294 2.41647L5.31098 6.99955Z" fill="white"/>
    </svg>
</template>

<script>
export default {
  name: 'ArrRight'
}
</script>

