<template>
    <div class="card-location grid">
        <div class="img img-ratio-location">
            <img :src="photo" class="w-100" :alt="siteName + '-' + title">
        </div>
        <div class="card-content">
            <h3 class="title mb-2">{{ title }}</h3>
            <div class="info">
                <p><i class="icon-phone"><IconPhoneSolid/></i><a :href="'tel:' + phone" @click="track('店頭-電話聯絡', phone)">{{ phone }}</a></p>
                <p><i class="icon-pin"><IconPinSolid/></i><a :href="'https://www.google.com.tw/maps?q=' + address" target="_blank" class="ml-2 link-underline address" @click="track('店頭-點地址', address)">{{ address }} </a></p>
            </div>
        </div>
    </div>
</template>

<script>
import IconPinSolid from '@/components/icons/IconPin_solid.vue'
import IconPhoneSolid from '@/components/icons/IconPhone_solid.vue'

export default {
    name: 'LocalBlock',
    components: {
        IconPinSolid,
        IconPhoneSolid
    },
    props: {
        title: String,
        phone: String,
        photo: String,
        address: String,
        about: Object,
        siteName: String,
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
}
</script>

