<template>
    <div class="home">
        <section class="main-slider p-0">
            <div class="full-wide">
                <div class="search-wrapper" v-if="hideSearch == 0">
                    <div class="search">
                        <i class="icon">
                            <IconSearch/>
                        </i>
                        <input type="text" class="form-control to-house" placeholder="開始尋找好屋">
                        <button type="submit" class="btn btn-link to-house">
                            搜尋
                        </button>
                    </div>
                </div>
                <div class="hero-banner banner-wrapper slick-slider">
                    <div class="slide-item" v-for="(banner, index) in bannerList" :key="index">
                        <div class="item-img" v-if="banner.bannerType == 1 && banner.targetType == 0">
                            <!-- 桌機圖 -->
                            <img :src="banner.imagePc" :alt="meta.alt.alt_1_1" class="w-100 d-none d-md-inline-block">
                            <!-- 手機圖 -->
                            <img :src="banner.imageMobile" :alt="meta.alt.alt_1_1" class="w-100 d-inline-block d-md-none">
                        </div>
                        <a :href="banner.href" :target="banner.target" v-if="banner.bannerType == 1 && banner.targetType > 0">
                            <div class="item-img">
                                <!-- 桌機圖 -->
                                <img :src="banner.imagePc" :alt="meta.alt.alt_1_1" class="w-100 d-none d-md-inline-block">
                                <!-- 手機圖 -->
                                <img :src="banner.imageMobile" :alt="meta.alt.alt_1_1" class="w-100 d-inline-block d-md-none">
                            </div>
                        </a>
                        <div class="ratio ratio-hero-video" v-if="banner.bannerType == 2">
                            <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+banner.videoId+'?rel=0&autoplay=' + autoplay[index]"
                                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen v-if="width > 767"></iframe>
                            <iframe id="vid" width="560" height="315" :src="'https://www.youtube.com/embed/'+banner.videoId+'?rel=0&muted=1&autoplay=' + autoplay[index]"
                                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen v-else></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="categories-section">
            <div class="container">
                <div class="categories-slider">
                    <div class="slider-item" v-for="(item, index) in this.houseTypeList" :key='index'>
                        <a :href="item.href" :target="item.target" @click="track('店頭-看物件', item.typeName)" v-if="!item.is_default">
                            <img :src="getImageUrl(item.image)" class="w-100" :alt="item.alt">
                            <h2>{{ item.typeName }}</h2>
                        </a>
                        <router-link :to="'collection?house_type[]=' + item.typeName" @click="track('店頭-看物件', item.typeName)" v-else>
                            <img :src="getImageUrl(item.image)" class="w-100" :alt="item.alt">
                            <h2>{{ item.typeName }}</h2>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <!-- 熱門物件 -->
        <section class="section-popular" v-if="show == 1 && recommendList.length > 0">
            <div class="container">
                <div class="d-flex">
                    <h2 class="section-title d-flex align-items-center mb-3">
                        <IconStar class="me-2"/>
                        {{ recommendTitle }}
                    </h2>
                    <div class="ms-auto popular-arrow">
                        <button class="slick-prev popular-prev customize">prev</button>
                        <button class="slick-next popular-next customize">next</button>
                    </div>
                </div>

                <div class="popular-slider">
                    <template v-for="item in recommendList">
                        <HouseCard
                            :siteName=website.siteName
                            :about=about
                            :item=item
                        ></HouseCard>
                    </template>
                </div>
            </div>
        </section>
        <section class="about-section million_star">
            <div class="container-fluid p-0">
                <div id="star_slider">
                    <div class="item" v-for="star in starList">
                        <div class="row mx-0">
                            <div class="col-about_img px-0 cover order-md-2">
                                <img :src="star.imagePc" :alt="star.title" v-if="about.image != undefined && about.image != '' && width > 767">
                                <img :src="star.imageMobile" :alt="star.title" v-if="about.image != undefined && about.image != '' && width <= 767">
                            </div>
                            <div class="col-about_content px-0 d-md-flex align-items-center justify-content-center order-md-1">
                                <div class="about-content px-4 text-left">
                                    <div class="section-heading no-border mb-3 mb-md-5">
                                        <h2 class="star-title mb-3">百萬之星</h2>
                                        <h3 class="star-subtitle">{{  star.title }}</h3>
                                    </div>
                                    <div class="star-subject">{{ star.subject }}</div>
                                    <div class="star-content" v-html="star.subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="about-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-about_img px-0 cover">
                        <img :src="about.image" :alt="website.siteName" class="w-100" v-if="about.image != undefined && about.image != ''">
                    </div>
                    <div class="col-about_content px-0 d-md-flex align-items-center">
                        <div class="about-content about-p text-left">
                            <div class="section-heading no-border mb-3">
                                <h2 class="section-title d-flex align-items-center mb-3">
                                    <IconHome class="me-2"/>
                                    {{ about.title }}
                                </h2>
                            </div>
                            <div class="about-inner" v-html="about.content"></div>
                            <div class="btn-wrapper">
                                <router-link to="about" class="btn btn-primary text about-more-btn">
                                    繼續閱讀
                                    <i class="arr-right">
                                        <ArrRight/>
                                    </i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="location-section section-padding" v-if="show == 1">
            <div class="container">
                <div class="d-flex">
                    <h2 class="section-title d-flex align-items-center mb-3">
                        <IconBuild class="me-2"/>
                        服務據點
                    </h2>
                    <div class="ms-auto d-flex d-lg-none location-arrow">
                        <button class="slick-prev location-prev slick-arrow customize">prev</button>
                        <button class="slick-next location-next slick-arrow customize">next</button>
                    </div>
                </div>
                <!-- 桌機版本 -->
                <div class="row g-2 g-lg-4 d-none d-lg-flex">
                    <div class="col-6 col-sm-4 col-lg-3" v-for="(item, index) in branchList" :key="index">
                        <LocalGrid :title="item.title" :phone="item.phone" :address="item.address" :photo="item.img" :about="about" :siteName="website.siteName"></LocalGrid>
                    </div>
                </div>
                <!-- 手機版本 -->
                <div class="location-slick d-block d-lg-none">
                    <template v-for="(item, index) in branchList">
                        <LocalGrid :title="item.title" :phone="item.phone" :address="item.address" :photo="item.img" :about="about" :siteName="website.siteName"></LocalGrid>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import ArrRight from '@/components/icons/ArrRight.vue'
import HouseCard from '@/components/HouseCard.vue'
import IconBuild from '@/components/icons/IconBuild.vue'
import IconHome from '@/components/icons/IconHome.vue'
import IconPin from '@/components/icons/IconPin.vue'
import IconStar from '@/components/icons/IconStar.vue'
import LocalBlock from '@/components/LocalBlock.vue'
import LocalGrid from '@/components/LocalGrid.vue'
import IconSearch from '@/components/icons/IconSearch.vue'

export default {
    name: 'Home',
    data() {
        return {
            show: 0,
            width: 0,
            bannerList: [],
            autoplay: [],
            houseTypeList: [],
            website: {},
            about: {},
            branchList: [],
            recommendTitle: '',
            recommendList: [],
            starList: [],
            hideSearch: 0,
        }
    },
    components: {
        ArrRight,
        HouseCard,
        IconBuild,
        IconHome,
        IconPin,
        IconStar,
        LocalBlock,
        LocalGrid,
        IconSearch
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
        handleOpenSelect: function (event) {
            if (event) {
                event.currentTarget.parentNode.classList.toggle('dropOpen')
            }
        },
        getImageUrl: function (image) {
            if (image.indexOf('@') == -1) {
                return image;
            }
            let imageName = image.split('-')[1];
            return require('@/assets/images/type-' + imageName)
        }
    },
    created() {
        let house = this;
        this.width = $(window).width();
        this.axios.get('/api/index')
            .then(response => (
                this.bannerList = response.data.bannerList,
                    this.houseTypeList = response.data.houseTypeList,
                    this.website = response.data.website,
                    this.about = response.data.about,
                    this.branchList = response.data.branchList,
                    this.meta = response.data.meta,
                    this.recommendTitle = response.data.recommendTitle,
                    this.recommendList = response.data.recommendList,
                    this.starList = response.data.starList,
                    document.title = this.meta.title,
                    $("meta[property='og:title']").attr("content", this.meta.title),
                    document.getElementsByTagName('meta')["description"].content = this.meta.description,
                    $("meta[property='og:description']").attr("content", this.meta.description),
                    window.setTimeout(function () {
                        $("#star_slider").slick({
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                            arrows: false,
                        })
                    }, 500),
                    this.show = 1
            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                $('.hero-banner').slick({
                    arrows: false,
                    dots: true,
                    infinite: true,
                    speed: 300,
                    autoplay: true,
                    // 自動播放毫秒數
                    autoplaySpeed: 5000,
                    // 滑入暫停自動播放
                    pauseOnHover: true
                });
                $('.hero-banner').on('afterChange', function (ev, slick, current, next) {
                    for (let x = 0; x < house.bannerList?.length; x++) {
                        house.autoplay[x] = 0;
                    }
                    let isYoutube = $('.slick-current').find('iframe').length;
                    if (isYoutube) {
                        house.autoplay[current] = 1;
                        house.hideSearch = 1;
                    } else {
                        house.hideSearch = 0;
                    }
                });
                // 熱門物件
                $(".popular-slider").slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: $('.popular-prev'),
                    nextArrow: $('.popular-next'),
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // centerMode: true,
                            // centerPadding: '250px',
                            variableWidth: true
                        }
                    }, {
                        breakpoint: 576,
                        settings: {
                            variableWidth: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
                // 物件類別
                $(".categories-slider").slick({
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false,
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }, {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            variableWidth: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });

                if ($('.location-slick').length) {
                    $('.location-slick').slick({
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: false,
                        prevArrow: $('.location-prev'),
                        nextArrow: $('.location-next'),
                        responsive: [{
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                variableWidth: true,
                            }
                        }, {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                variableWidth: true,
                            }
                        }]
                    });
                }
                if ($('.slick-current').find('iframe').length > 0) {
                    house.autoplay[0] = 1;
                    house.hideSearch = 1;
                }
            });
    },
    mounted() {
        let router = this;
        $('.to-house').click(function () {
            router.$router.push('collection')
        });
    }
}
</script>
