<template>
    <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.91522 3.58543L0.781698 5.71895L2.91522 3.58543L0.781719 1.45193L2.91522 3.58543L5.04873 1.45191L2.91522 3.58543L5.04878 5.71899L2.91522 3.58543Z" fill="#666666"/>
        <path d="M2.91522 3.58543L0.781698 5.71895L2.91522 3.58543L0.781719 1.45193L2.91522 3.58543L5.04873 1.45191L2.91522 3.58543L5.04878 5.71899L2.91522 3.58543Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>

