<template>
    <div class="card-product">
        <router-link :to="item.url" :target="width < 768 ? '_self' : '_blank'" @click="track('店頭-看物件', item.subject)">
            <div class="img">
                <div class="name green-top" v-if="item.communityName != '' && communityName == item.communityName"><div>{{ item.communityName }}</div></div>
                <div class="name black-top" v-else-if="item.communityName != '' && communityName != item.communityName"><div>{{ item.communityName }}</div></div>
                <div class="badge badge-type">{{ item.houseType }}</div>
                <div class="badge badge-view" v-if="item.vrLink != ''">全景</div>
                <div :class="'img-ratio-house' + (item.sell == 2 ? ' rent-tag' : '')">
                    <img :src="item.img" :alt="item.subject + '-' + siteName" class="w-100" v-if="item.img != ''">
                    <img :src="item.defaultImage" :alt="item.subject" class="w-100" v-else-if="item.defaultImage != ''">
                    <img src="/default/house.png" :alt="item.subject" class="w-100" v-else>
                </div>
            </div>
            <div class="card-body">
                <h3>{{ item.subject }}</h3>
                <p class="structure" v-if="item.pattern != ''">{{ item.pattern }}</p>
                <div class="info-group d-flex align-items-center">
                    <div>
                        <span class="me-2">{{ item.ping }}</span>
                        <span v-if="item.age != '' && item.sell == 1">{{ item.age }}</span>
                    </div>
                    <div class="price ms-auto" v-if="item.sell == 2">{{ item.rentPrice }}元/月</div>
                    <div class="price ms-auto" v-else-if="item.lowerPrice > 0">{{ item.lowerPrice }} 萬</div>
                    <div class="price ms-auto" v-else>{{ item.total }} 萬</div>
                </div>
            </div>
            <div class="card-footer pt-0">
                <p class="address">
                    <IconPin class="icon"/>
                    {{ item.address }}
                </p>
            </div>
        </router-link>
    </div>
</template>
<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'HouseCard',
    data() {
        return {
            width: 0
        };
    },
    props: {
        siteName: String,
        about: Object,
        item: Object,
        communityName: String,
    },
    components: {
        IconPin
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
        });
    }
}
</script>

