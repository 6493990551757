<template>
    <svg width="10.27" height="11.72" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2426 11.4926C10.6185 12.1168 9.38909 13.3462 8.41369 14.3216C7.63264 15.1026 6.36745 15.1027 5.5864 14.3217C4.62886 13.3641 3.42126 12.1565 2.75736 11.4926C0.414214 9.14949 0.414214 5.35051 2.75736 3.00736C5.10051 0.664214 8.89949 0.664214 11.2426 3.00736C13.5858 5.35051 13.5858 9.14949 11.2426 11.4926Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.25 7.25C9.25 8.49264 8.24264 9.5 7 9.5C5.75736 9.5 4.75 8.49264 4.75 7.25C4.75 6.00736 5.75736 5 7 5C8.24264 5 9.25 6.00736 9.25 7.25Z" stroke="#383838" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: 'IconPin'
}
</script>

