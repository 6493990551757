<template>
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.88883 2.18912C3.60713 0.470814 6.39306 0.470814 8.11137 2.18912C9.82967 3.90743 9.82967 6.69335 8.11137 8.41166L5.0001 11.5229L1.88883 8.41166C0.170521 6.69335 0.170521 3.90743 1.88883 2.18912ZM5.0001 7.20039C6.04944 7.20039 6.9001 6.34973 6.9001 5.30039C6.9001 4.25105 6.04944 3.40039 5.0001 3.40039C3.95076 3.40039 3.1001 4.25105 3.1001 5.30039C3.1001 6.34973 3.95076 7.20039 5.0001 7.20039Z" fill="#DD4C27" stroke="#DD4C27"/>
    </svg>
</template>

<script>
export default {
  name: 'IconPin_solid'
}
</script>

