<template>
    <div class="card-location row">
        <div class="col-sm-6">
            <div class="img img-ratio-location">
                <img :src="photo" class="w-100" :alt="siteName + '-' + title">
            </div>
        </div>
        <div class="col-sm-6 d-flex align-items-center border-bottom">
            <div class="card-content">
                <h3 class="title mb-3">{{ title }}</h3>
                <div class="info">
                    <p>電話：<a :href="'tel:' + phone" @click="track('店頭-電話聯絡', phone)">{{ phone }}</a></p>
                    <p>地址：<a :href="'https://www.google.com.tw/maps?q=' + address" target="_blank" class="ml-2" @click="track('店頭-點地址', address)">{{ address }} <i class="icon-pin">
                        <IconPin/>
                    </i></a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'LocalBlock',
    components: {
        IconPin
    },
    props: {
        title: String,
        phone: String,
        photo: String,
        address: String,
        about: Object,
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
}
</script>

